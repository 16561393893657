<template>
  <div>
    <div class="flix-form-group flix-html-strong">
      {{ $t('reminder.' + data.format) }} {{ data.trigger_int }} {{ $t('message.' + format) }} {{ $t('message.beforeAppointmentStarts') }}
    </div>
    <a @click.prevent="onDelete(item)" href="#" class="flix-btn flix-btn-danger">{{ $t('message.delete') }}</a> <a href="#" @click.prevent="onClose" class="flix-btn flix-btn-default">{{ $t('message.back') }}</a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object,
    item: Number,
    onDelete: Function,
    onClose: Function
  },
  data () {
    return {
      format: ''
    }
  },
  methods: {

  },
  mounted () {
    var newData = JSON.parse(JSON.stringify(this.data))
    if ((newData.trigger_int * 1) === 1) {
      this.format = newData.trigger_format.substring(0, newData.trigger_format.length - 1)
      return false
    }
    this.format = this.data.trigger_format
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
